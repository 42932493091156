import { AxiosResponse } from "axios";
import http from "../http";
import IArticleData from "../types/article.type";
import IBannerData from "src/types/baner.type";

class ArtsDataService {
  uploadImages(img_data: any) {
    return http.post("/upload-images", img_data, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  }

  async getAll(): Promise<AxiosResponse> {
    return await http.get<Array<IArticleData>>("/articles");
  }

  async getAllforPanel(): Promise<AxiosResponse> {
    return await http.get<Array<IArticleData>>("/panel-articles");
  }

  async get(id: string): Promise<AxiosResponse> {
    return await http.get<IArticleData>(`/articles/${id}`);
  }

  async create(data: IArticleData): Promise<AxiosResponse> {
    return await http.post<IArticleData>("/articles", data);
  }

  async update(data: IArticleData, id: any): Promise<AxiosResponse> {
    return await http.put<any>(`/articles/${id}`, data);
  }

  async update_visiblility(
    data: IArticleData,
    id: number
  ): Promise<AxiosResponse> {
    return await http.put<IArticleData>(`/article-visibility/${id}`, data);
  }

  async delete(id: any): Promise<AxiosResponse> {
    return await http.delete<any>(`/articles/${id}`);
  }

  async deleteAll(): Promise<AxiosResponse> {
    return await http.delete<any>(`/articles`);
  }

  async findByTitle(title: string): Promise<AxiosResponse> {
    const params = encodeURIComponent(title);
    return await http.get<Array<IArticleData>>(`/articles?title=${params}`);
  }

  async showArtbyId(id: string): Promise<AxiosResponse> {
    return await http.get<IArticleData>(`/article-by-id/${id}`);
  }

  async showByCategoryType(type: string): Promise<AxiosResponse> {
    return await http.get<Array<IArticleData>>(`/category/${type}`);
  }

  async getBanners(): Promise<AxiosResponse> {
    return await http.get<IBannerData>(`/banner`);
  }
  async findUrl(url: string): Promise<AxiosResponse> {
    return await http.get<Array<IArticleData>>(`/check-url/${url}`);
  }
}

const artsDataService = new ArtsDataService();
export default artsDataService;
