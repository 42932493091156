import React from "react";
import config from "src/config";
import { faCode } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

class AdminNavigator extends React.Component {
  constructor(props: any) {
    super(props);
    this.state = {};
  }

  showAdminBtn() {
    return (
      <Link to={"/admin-panel"} className="text-decoration-none">
        <button
          className="m-1 btn btn-outline-success"
          type="submit"
          style={{
            display: "block",
            marginTop: "auto",
            marginBottom: "auto",
          }}
        >
          Dodaj Artykuł
        </button>
      </Link>
    );
  }

  showMainPageBtn() {
    return (
      <Link to={"/"} target="_blank" className="text-decoration-none">
        <button
          className="m-1 btn btn-outline-success"
          type="submit"
          style={{
            display: "block",
            marginTop: "auto",
            marginBottom: "auto",
          }}
        >
          Strona główna
        </button>
      </Link>
    );
  }

  showBannerBtn() {
    return (
      <Link to={"/banner-section"} className="text-decoration-none">
        <button
          className="m-1 btn btn-outline-success"
          type="submit"
          style={{
            display: "block",
            marginTop: "auto",
            marginBottom: "auto",
          }}
        >
          Banery
        </button>
      </Link>
    );
  }

  showArtsBtn() {
    return (
      <Link to={"/arts-section"} className="text-decoration-none">
        <button
          className="m-1 btn btn-outline-success"
          type="submit"
          style={{
            display: "block",
            marginTop: "auto",
            marginBottom: "auto",
          }}
        >
          Artykuły
        </button>
      </Link>
    );
  }

  showFooterBtn() {
    return (
      <Link to={"/footer-menu"} className="text-decoration-none">
        <button
          className="m-1 btn btn-outline-success"
          type="submit"
          style={{ display: "block", marginTop: "auto", marginBottom: "auto" }}
        >
          Footer
        </button>
      </Link>
    );
  }

  showLogoutBtn() {
    return (
      <button
        className="m-1 btn btn-outline-success"
        type="submit"
        style={{
          display: "block",
          marginTop: "auto",
          marginBottom: "auto",
        }}
        onClick={() => {
          localStorage.removeItem("token");
          localStorage.removeItem("currentArtTags");
          window.location.href = "/admin-panel";
        }}
      >
        Logout
      </button>
    );
  }

  showAboutBtn() {
    return (
      <Link to={"/edit-about"} className="text-decoration-none">
        <button
          className="m-1 btn btn-outline-success"
          type="submit"
          style={{ display: "block", marginTop: "auto", marginBottom: "auto" }}
        >
          Edit About page
        </button>
      </Link>
    );
  }

  render() {
    return (
      <nav className="navbar navbar-expand-lg bg-light sticky-top p-3">
        <div className="container-fluid">
          <div className="nav-item px-2">
            <FontAwesomeIcon icon={faCode}></FontAwesomeIcon>
          </div>
          <a className="navbar-brand underline" href={config.host_url}>
            {config.page_name}
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            {localStorage.getItem("token") !== null ? (
              <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                <li>{this.showLogoutBtn()}</li>
                <li>{this.showBannerBtn()}</li>
                <li>{this.showArtsBtn()}</li>
                <li>{this.showAdminBtn()}</li>
                <li>{this.showMainPageBtn()}</li>
                <li>{this.showFooterBtn()}</li>
                <li>{this.showAboutBtn()}</li>
              </ul>
            ) : (
              ""
            )}
          </div>
        </div>
      </nav>
    );
  }
}

export default AdminNavigator;
