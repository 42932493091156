import AdminNavigator from "./AdminNavigator";
import React from "react";
import { motion } from "framer-motion";
import { Container } from "react-bootstrap";
import FooterMenu1 from "./FooterMenu1";
import FooterMenu2 from "./FooterMenu2";

class FooterConfig extends React.Component {
  constructor(props: any) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.3 }}
      >
        <AdminNavigator />
        <Container>
          <FooterMenu1 />
          <FooterMenu2 />
        </Container>
      </motion.div>
    );
  }
}

export default FooterConfig;
