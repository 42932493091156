import React from "react";
import NavigatorMenu from "./Navigator";

class AboutPage extends React.Component {
  constructor(props: any) {
    super(props);
  }
  render() {
    return (
      <div>
        <NavigatorMenu />
      </div>
    );
  }
}

export default AboutPage;
