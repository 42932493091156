import "../style.css";
import "bootstrap/dist/css/bootstrap.css";
import { Component } from "react";
import userData from "../types/user.type";
import UserDataService from "../services/login.service";
import AddTutorial from "./add-art.component";
import Footer from "../components/Footer";
import "jquery/dist/jquery.min.js";
import "bootstrap/dist/js/bootstrap.min.js";
import { motion } from "framer-motion";
import AdminNavigator from "./AdminNavigator";

type Props = {};

type State = userData;

class AdminPanel extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.tryLogin = this.tryLogin.bind(this);
    this.inputChange = this.inputChange.bind(this);
    this.state = {
      username: "",
      password: "",
      isAdmin: false,
      falseLogin: false,
      token:
        localStorage.getItem("token") == null
          ? ""
          : (localStorage.getItem("token") as string),
    };
  }

  tryLogin() {
    const data: userData = {
      username: this.state.username,
      password: this.state.password,
      isAdmin: this.state.isAdmin,
    };
    UserDataService.tryLogin(data)
      .then((response: any) => {
        localStorage.setItem("token", response.data);
        this.setState({
          token:
            localStorage.getItem("token") == null
              ? ""
              : (localStorage.getItem("token") as string),
          falseLogin: false,
        });
      })
      .catch((e: Error) => {
        this.setState({ falseLogin: true });
      });
  }

  inputChange(event: React.ChangeEvent<HTMLInputElement>) {
    this.setState({
      [event.target.id]: event.target.value,
    });
  }

  generatePanel() {
    return (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.3 }}
      >
        <AdminNavigator />
        <AddTutorial />;
        <Footer />
      </motion.div>
    );
  }

  generateLoginForm() {
    return (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.3 }}
      >
        <AdminNavigator />
        <div className="back">
          <div className="div-center">
            <div className="content">
              <h3 className="text-center">PANEL STEROWANIA</h3>
              <form>
                <div className="form-group">
                  <input
                    type="login"
                    id="username"
                    className="form-control"
                    onChange={(e: any) => this.inputChange(e)}
                  />
                  <label className="form-label" htmlFor="username">
                    Login
                  </label>
                </div>

                <div className="form-group">
                  <input
                    type="password"
                    id="password"
                    className="form-control"
                    onChange={(e: any) => this.inputChange(e)}
                  />
                  <label className="form-label" htmlFor="password">
                    Hasło
                  </label>
                </div>
                <button
                  type="button"
                  className="d-block m-auto btn btn-primary mb-4"
                  onClick={this.tryLogin}
                >
                  Zaloguj
                </button>
              </form>
              {this.state.falseLogin === true ? (
                <div className="text-center">NIEPRAWIDŁOWE DANE LOGOWANIA</div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
        <Footer />
      </motion.div>
    );
  }

  render() {
    window.scrollTo({ top: 0, behavior: "smooth" });
    return this.state.token !== ""
      ? this.generatePanel()
      : this.generateLoginForm();
  }
}

export default AdminPanel;
