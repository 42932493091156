import {
  FC,
  useState,
  useRef,
  useMemo,
  ReactElement,
  RefObject,
  useEffect,
} from "react";
import TutorialDataService from "../services/art.service";
import IArticleData from "../types/article.type";
import ReactQuill from "react-quill";
import EdTags from "./EdTags";
import "react-quill/dist/quill.snow.css";
import "bootstrap/dist/css/bootstrap.css";
import hljs from "highlight.js";
import "jquery/dist/jquery.min.js";
import "bootstrap/dist/js/bootstrap.min.js";
import Quill from "quill";
import MagicUrl from "quill-magic-url";
import config from "../config";
import { useParams } from "react-router-dom";
import AdminNavigator from "./AdminNavigator";
import { Container } from "react-bootstrap";
Quill.register("modules/magicUrl", MagicUrl);
hljs.configure({
  languages: [
    "javascript",
    "ruby",
    "python",
    "rust",
    "c++",
    "bash",
    "typescript",
    "php",
    "html",
  ],
});

async function udateArtInDatabase(
  content: any,
  title: any,
  summary: string,
  tags: string,
  id: string,
  url: string
): Promise<number> {
  const data: IArticleData = {
    title: title,
    content: content,
    description: summary,
    TAGS: tags,
    id: id,
    url: url,
  };
  return await TutorialDataService.update(data, data.id)
    .then((res: any) => {
      return 2;
    })
    .catch((err: any) => {
      return -1;
    });
}

const createDescModule = () => {
  const toolbar = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link"],
      ["clean"],
    ],
  };
  return toolbar;
};

const imageHandler = async (val: any, quillRef: RefObject<ReactQuill>) => {
  const input = document.createElement("input");
  input.setAttribute("type", "file");
  input.setAttribute("accept", "image/*");
  input.click();
  input.onchange = async () => {
    var file: any = input && input.files ? input.files[0] : null;
    var formData = new FormData();
    formData.append("image", file);
    if (quillRef.current !== null) {
      let quillObj = quillRef.current.getEditor();
      console.log(file);
      fetch(`${config.api_url}/api/images-upload/`, {
        method: "POST",
        body: formData,
      })
        .then((response) => {
          console.log(response);
          let range: any;
          range = quillObj.getSelection();
          console.log(range);
          if (range !== null) {
            quillObj.insertEmbed(
              range,
              "image",
              `${config.api_url}/uploads/` + file.name
            );
          }
          quillObj.setSelection(range.index + 1);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  };
};

const createDescFormats = () => {
  const formats_desc = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "align",
    "strike",
    "script",
    "blockquote",
    "background",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "video",
    "color",
    "code-block",
  ];
  return formats_desc;
};

const renderMainContainer = () => {
  const container = [
    [{ font: [] }, { size: [] }, { header: [1, 2, 3, 4, 5, 6] }],
    ["bold", "italic", "underline", "strike"],
    [{ color: [] }, { background: [] }],
    [{ script: "sub" }, { script: "super" }],
    [{ header: 1 }, { header: 2 }, "blockquote", "code-block"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    [
      { align: "" },
      { align: "center" },
      { align: "right" },
      { align: "justify" },
    ],
    ["link", "image", "video"],
    [{ script: "sub" }, { script: "super" }],
    ["clean"],
    ["code-block"],
  ];
  return container;
};

const ArtUpdater: FC = (): ReactElement => {
  const { id } = useParams();
  const quillRef = useRef<ReactQuill>(null);
  const [title, setTitle] = useState("");
  const [summary, setSummary] = useState("");
  const [content, setContent] = useState("");
  const [is_updated_true, setUpdated] = useState(0);
  const [tags, setTags] = useState<Array<string>>([]);
  const [url, setUrl] = useState("");
  const modules_desc = createDescModule();
  useEffect(() => {
    const find_art = async () => {
      await TutorialDataService.showArtbyId(id as string).then(
        (result: any) => {
          setTitle(result.data.title);
          setSummary(result.data.description);
          setContent(result.data.content);
          setUrl(result.data.url);
          if (result.data.TAGS !== null && result.data.TAGS !== "") {
            setTags(
              JSON.parse(
                result.data.TAGS.length > 0 ? result.data.TAGS : Array<string>
              )
            );
          } else {
            setTags([]);
          }
        }
      );
    };
    find_art();
  }, [id]);
  const modules = useMemo(
    () => ({
      syntax: true,
      magicUrl: true,
      toolbar: {
        container: renderMainContainer(),
        handlers: {
          image: (val: any) => imageHandler(val, quillRef),
        },
      },
    }),
    []
  );

  const formats_desc = createDescFormats();

  return (
    <div>
      <AdminNavigator />
      <Container>
        <div className="submit-form">
          <div className="editor-r">
            <input
              className="form-control"
              type="title"
              placeholder="Title"
              onChange={(event) => setTitle(event.target.value)}
              value={title}
            />
            <p>Description</p>
            <input
              className="form-control"
              type="title"
              placeholder="Url"
              onChange={(event) => setUrl(event.target.value)}
              value={url}
            />
            <p>Url</p>
            <ReactQuill
              theme="snow"
              modules={modules_desc}
              formats={formats_desc}
              onChange={(value) => setSummary(value)}
              value={summary}
            />
            <p>Body:</p>
            <ReactQuill
              theme="snow"
              modules={modules}
              formats={formats_desc}
              onChange={(value) => setContent(value)}
              value={content}
              ref={quillRef}
            />
            <EdTags tags={tags} />
            <div className="mt-2 mb-2">
              <button
                className="btn btn-success d-block w-25 m-lg-auto m-xl-auto m-auto"
                onClick={(e) =>
                  udateArtInDatabase(
                    content,
                    title,
                    summary as string,
                    localStorage.getItem("TAGS") as string,
                    id as string,
                    url as string
                  ).then((res) => {
                    localStorage.removeItem("TAGS");
                    setUpdated(2);
                  })
                }
              >
                Aktualizuj
              </button>
            </div>
            {is_updated_true === 2 ? (
              <div className="alert alert-success text-center">
                Artykuł został zaktualizowany
              </div>
            ) : is_updated_true === -1 ? (
              <div className="false">
                <p className="text-center">
                  Był jakis problem z aktualizacją artykułu
                </p>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </Container>
    </div>
  );
};

export default ArtUpdater;
