import React, { ChangeEvent } from "react";
import { Button } from "react-bootstrap";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { Link } from "react-router-dom";
import FooterDataService from "../services/footer.service";
import IFooterElem from "./FooterElem";

interface States {
  footer_elem1: Array<IFooterElem>;
  updated: number;
  length_menu1: number;
  to_much: number;
}

class FooterMenu1 extends React.Component<{}, States> {
  constructor(props: any) {
    super(props);

    this.onDragEndMenu = this.onDragEndMenu.bind(this);
    this.saveFooterMenu = this.saveFooterMenu.bind(this);
    this.loadFooterMenu = this.loadFooterMenu.bind(this);

    this.state = {
      footer_elem1: [],
      updated: 0,
      length_menu1: 0,
      to_much: 0,
    };

    FooterDataService.getFooterMenu1()
      .then((data: any) => {
        console.log(data);
        this.setState({
          footer_elem1: JSON.parse(data.data["footer_menu1"]),
          length_menu1: JSON.parse(data.data["footer_menu1"]).length,
          to_much: 0,
          updated: 0,
        });
      })
      .catch((err: any) => {
        console.log(err);
      });
  }

  reorder(list: Array<IFooterElem>, startIndex: number, endIndex: number) {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  }

  onBeforeCapture() {}
  onBeforeDragStart() {}
  onDragStart() {}
  onDragUpdate() {}
  onDragEndMenu(result: any) {
    if (!result.destination) {
      return;
    }
    const items: Array<IFooterElem> = this.reorder(
      this.state.footer_elem1,
      result.source.index,
      result.destination.index
    );
    this.setState({
      footer_elem1: items,
    });
  }

  changeInputTextUrl(e: ChangeEvent<HTMLInputElement>, index: number) {
    const elem = this.state.footer_elem1;
    elem[index].link = e.target.value;
    this.setState({
      footer_elem1: elem,
    });
  }
  

  changeInputTextName(e: ChangeEvent<HTMLInputElement>, index: number) {
    const elem = this.state.footer_elem1;
    elem[index].name = e.target.value;
    this.setState({
      footer_elem1: elem,
    });
  }

  addFooterElem1(e: any) {
    if (this.state.footer_elem1.length > 4) {
      this.setState({ to_much: 1 });
      return;
    }
    const elem = {
      id: String(this.state.footer_elem1.length + 1),
      link: "",
      name: "",
    };

    const newArray = [...this.state.footer_elem1, elem];

    this.setState({ footer_elem1: newArray });
  }

  addFooterElem(e: any) {
    if (this.state.footer_elem1.length > 4) {
      this.setState({ to_much: 2 });
      return;
    }
    const elem = {
      id: String(this.state.footer_elem1.length + 1),
      link: "",
      name: "",
    };

    const newArray = [...this.state.footer_elem1, elem];

    this.setState({ footer_elem1: newArray });
  }

  loadFooterMenu() {
    FooterDataService.getFooterMenu1()
      .then((data) => {
        this.setState({});
      })
      .catch((err) => {
        console.log(err);
      });
  }
  saveFooterMenu(e: any) {
    console.log("footerMenu" + JSON.stringify(this.state.footer_elem1));
    const elem = {
      id: 1,
      footer_menu1: JSON.stringify(this.state.footer_elem1),
      footer_menu2: "",
    };
    FooterDataService.setFooterMenus1(elem)
      .then((response: any) => {
        this.setState({ updated: 1 });
      })
      .catch((error: any) => {
        this.setState({ updated: -1 });
      });
  }

  handleDeleteInput(index: number) {}

  render() {
    return (
      <div>
        <h3 className="d-block mx-auto text-center">Menu 1</h3>
        <DragDropContext onDragEnd={this.onDragEndMenu}>
          <Droppable droppableId="droppable">
            {(provider, snapshot) => (
              <div
                {...provider.droppableProps}
                ref={provider.innerRef}
                style={{
                  background: snapshot.isDraggingOver ? "lightgreen" : "white",
                  padding: "10px",
                  transition: "background 0.3s ease",
                }}
              >
                {this.state.footer_elem1.map((item, index) => (
                  <Draggable key={item.id} draggableId={item.id} index={index}>
                    {(provided, snapshot) => (
                      <div
                        className="m-2 input-group mb-3"
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        key={index}
                      >
                        <input
                          name={item.id}
                          type="text"
                          value={item.link}
                          onChange={(e) => {
                            this.changeInputTextUrl(e, index);
                          }}
                          className="form-control m-2"
                        />
                        <input
                          type="text"
                          className="form-control m-2"
                          value={item.name}
                          onChange={(e) => {
                            this.changeInputTextName(e, index);
                          }}
                        />
                        <div className="input-group-append">
                          <button
                            className="btn btn-outline-danger m-2"
                            type="button"
                            onClick={() => this.handleDeleteInput(index)}
                          >
                            Delete
                          </button>
                        </div>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provider.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        {this.state.to_much == 1 ? (
          <div className="warring">ZA DUZO ELEMENTÓW W MENU</div>
        ) : null}
        <Button
          className="m-2 mx-auto d-block"
          onClick={(event) => this.addFooterElem(event)}
        >
          Add element
        </Button>
        <Button
          className="m-2 mx-auto d-block"
          onClick={(event) => this.saveFooterMenu(event)}
        >
          Save
        </Button>
      </div>
    );
  }
}

export default FooterMenu1;
