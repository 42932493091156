import React, { ChangeEvent, ReactElement, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "bootstrap/dist/js/bootstrap.min.js";
import "../style.css";
import { faRemove } from "@fortawesome/free-solid-svg-icons";

const KeyCodes = {
  comma: 188,
  enter: 13,
};

interface Props {
  tags: Array<string>;
}

interface CProps {
  TAGS: Array<string>;
}

interface States {
  tags: Array<string>;
  currentTag: string;
}

class Tags extends React.Component<CProps, States> {
  constructor(props: CProps) {
    console.log(props);
    super(props);
    this.updateCurrentTag = this.updateCurrentTag.bind(this);
    this.checkKeyDownTags = this.checkKeyDownTags.bind(this);
    this.tagDelete = this.tagDelete.bind(this);
    this.componentDidMount = this.componentDidMount.bind(this);
    this.state = {
      tags: [],
      currentTag: "",
    };
  }

  componentDidMount(): void {
    if (this.props.TAGS.length > 0) {
      console.log(this.props.TAGS);
      this.setState({ tags: this.props.TAGS, currentTag: "" });
    }
  }

  checkKeyDownTags(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.keyCode === KeyCodes.comma || e.keyCode === KeyCodes.enter) {
      e.preventDefault();
      if (this.state.currentTag.length > 0) {
        localStorage.setItem(
          "TAGS",
          JSON.stringify([...this.state.tags, this.state.currentTag])
        );
        this.setState({
          tags: [...this.state.tags, this.state.currentTag],
          currentTag: "",
        });
        e.currentTarget.value = "";
      }
    }
  }

  updateCurrentTag(e: ChangeEvent<HTMLInputElement>) {
    this.setState({ currentTag: e.currentTarget.value });
  }

  tagDelete(elem: number) {
    localStorage.setItem(
      "TAGS",
      JSON.stringify(
        this.state.tags.filter((value: string, index: number) => index !== elem)
      )
    );
    this.setState({
      tags: this.state.tags.filter(
        (value: string, index: number) => index !== elem
      ),
    });
  }

  render() {
    return (
      <div className="d-block position-relative">
        <div className="d-flex">
          {this.state.tags.map((value: string, index: number) => {
            return (
              <div className="tag d-flex m-1" key={index}>
                <div className="tag-elem bg-dark text-white">
                  <div className="d-inline">{value}</div>
                  <FontAwesomeIcon
                    icon={faRemove}
                    className="tag-delete m-1 d-inline"
                    onClick={(e: React.MouseEvent<SVGSVGElement>) =>
                      this.tagDelete(index)
                    }
                  />
                </div>
              </div>
            );
          })}
        </div>
        <input
          className="m-2 d-block"
          type="text"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            this.updateCurrentTag(e)
          }
          onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
            this.checkKeyDownTags(e);
          }}
        />
      </div>
    );
  }
}

const TagsBox: React.FC<Props> = ({ tags }): ReactElement => {
  const [t, setTags] = useState(tags);
  const [r, setRender] = useState(false);
  useEffect(() => {
    const tagsCheck = async () => {
      if (tags.length > 0) {
        setTags(tags);
        setRender(true);
      }
    };
    const check = async () => {
      await tagsCheck();
    };
    check();
  }, [tags, t]);
  if (r) {
    return <Tags TAGS={t} />;
  }
  return (
    <div>
      <Tags TAGS={t} />
    </div>
  );
};

export default TagsBox;
