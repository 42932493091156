import "bootstrap/dist/css/bootstrap.css";
import React, { useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "jquery/dist/jquery.min.js";
import "bootstrap/dist/js/bootstrap.min.js";
import ITutorialData from "../types/article.type";
import { ChangeEvent } from "react";
import TutorialDataService from "../services/art.service";
import { Link } from "react-router-dom";
import { format } from "date-fns";
import parse from "html-react-parser";
import { faCode } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import config from "../config";

type State = {
  arts: Array<ITutorialData>;
  complete: Array<ITutorialData>;
  currentArt: ITutorialData;
  currentIndex: number;
  searchTitle: string;
  postsPerPage: number;
  page: number;
  postsCount: number;
  showPage: boolean;
};

export class SearchList extends React.Component<ITutorialData, State> {
  constructor(props: ITutorialData) {
    super(props);
    this.onChangeSearchTitle = this.onChangeSearchTitle.bind(this);
    this.searchTitle = this.searchTitle.bind(this);
    this.setDefaultStates();
    if (
      props.title !== "" &&
      props.title !== null &&
      props.title !== undefined
    ) {
      this.setState({
        searchTitle: props.title !== undefined ? props.title : "",
      });
    }
  }

  setDefaultStates() {
    this.setState({
      arts: [],
      complete: [],
      currentArt: {},
      currentIndex: -1,
      searchTitle: "",
      page: 1,
      postsCount: 0,
      postsPerPage: 5,
      showPage: false,
    });
  }

  searchTitle() {
    this.setState({
      currentArt: {},
      currentIndex: -1,
    });

    TutorialDataService.findByTitle(this.state.searchTitle)
      .then((response: any) => {
        const currentArts: ITutorialData[] = response.data;
        this.setState({
          page: 1,
          arts: currentArts.slice(
            this.state.page * this.state.postsPerPage - this.state.postsPerPage,
            this.state.page * this.state.postsPerPage
          ),
          postsCount: currentArts.length,
        });
      })
      .catch((e: Error) => {});
  }

  componentDidMount() {
    this.searchTitle();
  }

  onChangeSearchTitle(e: ChangeEvent<HTMLInputElement>) {
    const searchTitle = e.target.value;

    this.setState({
      searchTitle: searchTitle,
    });
  }

  retrieveTutorials() {
    TutorialDataService.getAll()
      .then((response: any) => {
        this.setState({
          arts: response.data,
          postsCount: response.data.length,
          showPage: true,
        });
      })
      .catch((e: Error) => {});
  }

  refreshList() {
    this.retrieveTutorials();
    this.setState({
      currentArt: {},
      currentIndex: -1,
    });
  }

  setActiveTutorial(tutorial: ITutorialData, index: number) {
    this.setState({
      currentArt: tutorial,
      currentIndex: index,
    });
  }

  removeAllTutorials() {
    TutorialDataService.deleteAll()
      .then((response: any) => {
        this.refreshList();
      })
      .catch((e: Error) => {});
  }

  displayAllArts() {
    const currentArts = this.state.arts.slice(0, this.state.postsPerPage);
    return (
      <div>
        {currentArts.map((currentArts: ITutorialData, index: number) => (
          <div className="card p-3 m-3 shadow-sm" key={index}>
            <div className="card-body">
              <h5 className="card-title">{currentArts.title}</h5>
              <div className="card-text">
                {parse(currentArts.description as string)}
              </div>
              <div className="card-text">
                <small className="text-muted">
                  {format(new Date(currentArts.createdAt), "yyyy-mm-dd")}
                </small>
              </div>
            </div>
            <Link
              to={`/article/${currentArts.id}`}
              className="thread-onlist-link"
            >
              Czytaj więcej...
            </Link>
          </div>
        ))}
      </div>
    );
  }

  render() {
    if (this.state.showPage === true) {
      return <div>{this.displayAllArts()}</div>;
    }
    return <div></div>;
  }
}

export default function NavigatorMenu() {
  const [searchTitle, setTitle] = React.useState("");

  const ChangeSearchTitle = (e: ChangeEvent<HTMLInputElement>) => {
    setTitle(e.target.value);
  };
  useEffect(() => {
    setTitle(searchTitle);
  }, [searchTitle]);

  return (
    <nav className="navbar navbar-expand-lg bg-light sticky-top p-3">
      <div className="container-fluid">
        <div className="nav-item px-2">
          <FontAwesomeIcon icon={faCode}></FontAwesomeIcon>
        </div>
        <a className="navbar-brand underline" href={config.host_url}>
          {config.page_name}
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            <li className="nav-item">
              <Link
                className="nav-link active"
                aria-current="page"
                to="/category/typescript"
              >
                Typescript
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/category/php">
                PHP
              </Link>
            </li>
            <li className="nav-item dropdown">
              <Link
                className="nav-link dropdown-toggle"
                to="/category/c++"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                C++
              </Link>
              <ul className="dropdown-menu">
                <li>
                  <Link className="dropdown-item" to="/category/c++">
                    All C++ Arts
                  </Link>
                </li>
                <li>
                  <Link className="dropdown-item" to="/category/qt">
                    QT
                  </Link>
                </li>
                <li>
                  <Link className="dropdown-item" to="#">
                    STL
                  </Link>
                </li>
                <li>
                  <hr className="dropdown-divider" />
                </li>
                <li>
                  <Link className="dropdown-item" to="#">
                    BOOST
                  </Link>
                </li>
              </ul>
            </li>
          </ul>
          <form className="d-flex" role="search">
            <input
              className="form-control me-2"
              type="search"
              placeholder="Szukaj..."
              aria-label="Searchj..."
              style={{
                display: "block",
                marginTop: "auto",
                marginBottom: "auto",
              }}
              onChange={ChangeSearchTitle}
            />
            <Link
              className="text-decoration-none"
              to={"/search-arts-by-title/" + searchTitle}
            >
              <button
                className="btn btn-outline-success"
                type="submit"
                style={{
                  display: "block",
                  marginTop: "auto",
                  marginBottom: "auto",
                }}
              >
                Szukaj
              </button>
            </Link>
          </form>
        </div>
      </div>
    </nav>
  );
}
