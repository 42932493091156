import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import IArticleData from "src/types/article.type";
import Footer from "./Footer";
import E404 from "./E404";
import { Helmet } from "react-helmet";
import ReactQuill from "react-quill";
import NavigatorMenu from "./Navigator";
import { motion } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBackward } from "@fortawesome/free-solid-svg-icons";
import hljs from "highlight.js";
import "jquery/dist/jquery.min.js";
import "bootstrap/dist/js/bootstrap.min.js";
import "highlight.js/styles/monokai-sublime.css";
import "react-quill/dist/quill.snow.css";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/css/bootstrap.css";
import config from "../config";
import Banners from "./Banners";

hljs.configure({
  languages: [
    "javascript",
    "ruby",
    "python",
    "rust",
    "c++",
    "bash",
    "typescript",
    "php",
    "html",
  ],
});

function createSEOpage(title: string, description: string) {
  return (
    <Helmet>
      <title>{title + " - Computer science world - dev-data.pl"}</title>
      <meta
        name={description}
        content={
          "C++, TYPESCRIPT, JAVASCRIPT, PHP, MYSQL, MSSQL, REACT, QT, STL, BOOST"
        }
      />
    </Helmet>
  );
}

function displayArt(data: IArticleData) {
  return (
    <div>
      <div className="container">
        {createSEOpage(data.title as string, data.description as string)}
        <NavigatorMenu />
        <div className="p-2 m-2 d-block m-lg-auto m-xl-auto text-center">
          <h2>{data.title}</h2>
        </div>
        <div className="m-2 p-2 d-block m-lg-auto m-xl-auto">
          <ReactQuill
            readOnly
            theme="snow"
            modules={{
              syntax: true,
              toolbar: false,
            }}
            value={data.content}
          />
        </div>
        <div
          className="text-center d-block m-lg-auto m-xl-auto p-2 m-2"
          title="Powrót"
          onClick={(e) => {
            window.history.back();
          }}
        >
          <FontAwesomeIcon
            icon={faBackward}
            fontSize={32}
            className="IconFontAwesome"
          />
        </div>
      </div>
      <Banners />
      <Footer />
    </div>
  );
}

function loadingProgress() {
  console.log("loading");
  return (
    <div className="loader-container">
      <div className="spinner"></div>
    </div>
  );
}

export default function ShowArt() {
  const [artData, setArtData] = useState<IArticleData>({});
  const [loading, setLoading] = useState(-1);
  const { id } = useParams();
  useEffect(() => {
    if (loading === -1) {
      const getArt = async () => {
        const response = await fetch(`${config.api_url}/api/article/${id}`);
        if (response.ok) {
          response
            .json()
            .then((artData: IArticleData) => {
              if (artData.content === "") {
                setLoading(0);
              } else {
                setLoading(1);
              }
              setArtData(artData);
            })
            .catch((error) => {
              setLoading(0);
            });
        } else {
          setLoading(0);
        }
      };
      getArt();
    }
  }, [id, loading]);
  if (loading === -1) {
    return loadingProgress();
  } else if (loading === 0) {
    return (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.3 }}
      >
        <E404 />
      </motion.div>
    );
  }
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.3 }}
    >
      {displayArt(artData)}
    </motion.div>
  );
}
