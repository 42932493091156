import React, { ChangeEvent } from "react";
import NavigatorMenu from "./Navigator";
import "../style.css";
import { Form, Container, FormGroup, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClipboard } from "@fortawesome/free-solid-svg-icons";

interface Props {}

interface States {
  hex: string;
  format: string;
  r: number;
  g: number;
  b: number;
  colInfoSet: boolean;
  copiedRGB: boolean;
  copiedHEX: boolean;
}

class ColorsTool extends React.Component<Props, States> {
  constructor(props: Props) {
    super(props);

    this.state = {
      hex: "",
      format: "0",
      r: 0,
      g: 0,
      b: 0,
      colInfoSet: false,
      copiedRGB: false,
      copiedHEX: false,
    };
    this.stateColor = this.stateColor.bind(this);
    this.stateFormat = this.stateFormat.bind(this);
    this.stateColor_r = this.stateColor_r.bind(this);
    this.stateColor_g = this.stateColor_g.bind(this);
    this.stateColor_b = this.stateColor_b.bind(this);
    this.setColorInfo = this.setColorInfo.bind(this);
    this.hexToRgb = this.hexToRgb.bind(this);
  }
  stateColor(colorInput: ChangeEvent<HTMLInputElement>) {
    this.setState({ hex: colorInput.target.value });
  }
  stateFormat(formatOption: ChangeEvent<HTMLInputElement>) {
    const format = {
      format: formatOption.target.value,
      r: 0,
      g: 0,
      b: 0,
      hex: "",
      copiedRGB: false,
      copiedHEX: false,
    };
    this.setState(format);
  }
  stateColor_r(colorInput: ChangeEvent<HTMLInputElement>) {
    if (colorInput.target.valueAsNumber > 255) {
      colorInput.target.value = "255";
    }
    this.setState({ r: colorInput.target.valueAsNumber });
  }

  stateColor_g(colorInput: ChangeEvent<HTMLInputElement>) {
    if (colorInput.target.valueAsNumber > 255) {
      colorInput.target.value = "255";
    }
    this.setState({ g: colorInput.target.valueAsNumber });
  }

  stateColor_b(colorInput: ChangeEvent<HTMLInputElement>) {
    if (colorInput.target.valueAsNumber > 255) {
      colorInput.target.value = "255";
    }
    this.setState({ b: colorInput.target.valueAsNumber });
  }

  generate_hex_elem() {
    return (
      <FormGroup className="m-3">
        <Form.Label className="w-100 text-center">HEX FORMAT</Form.Label>
        <div className="col-sm">
          <Form.Control
            className="form-control d-block m-auto mt-1 mb-1"
            type="text"
            onChange={this.stateColor}
          />
        </div>
      </FormGroup>
    );
  }

  hexToRgb(hex: string) {
    var result = /^#?([a-f\d]{1,2})([a-f\d]{1,2})([a-f\d]{1,2})$/i.exec(hex);
    if (result) {
      return (
        parseInt(result[1], 16).toString() +
        "," +
        parseInt(result[2], 16).toString() +
        ", " +
        parseInt(result[3], 16)
      );
    } else {
      return "error parsing";
    }
  }

  allColorInfo() {
    const classRGB = this.state.copiedRGB
      ? "col-sm-10 p-2 border-success border rounded-1"
      : "col-sm-10 p-2";
    const classHEX = this.state.copiedHEX
      ? "col-sm-10 p-2 border-success border rounded-1"
      : "col-sm-10 p-2";
    const bg =
      this.state.format === "0"
        ? { background: "#" + this.state.hex }
        : {
            background: `rgb(${this.state.r}, ${this.state.g}, ${this.state.b})`,
          };
    return (
      <div>
        <div className="p-5 m-3" style={bg}></div>
        <div className="bg-light m-3 p-3 row">
          <div className={classRGB}>
            {this.state.format === "0"
              ? `rgb(
            ${this.hexToRgb(this.state.hex)})`
              : `rgb(${this.state.r}, ${this.state.g}, ${this.state.b})`}
          </div>
          <Button
            onClick={() => {
              navigator.clipboard.writeText(
                this.state.format === "0"
                  ? `rgb(
            ${this.hexToRgb(this.state.hex)})`
                  : `rgb(${this.state.r}, ${this.state.g}, ${this.state.b})`
              );
              this.setState({ copiedRGB: true });
            }}
            className="col-sm-2 btn btn-outline-light"
          >
            <FontAwesomeIcon icon={faClipboard}></FontAwesomeIcon>
          </Button>
        </div>
        <div className="bg-light m-3 p-3 row">
          <div className={classHEX}>
            #
            {this.state.format === "1"
              ? this.state.r.toString(16).toUpperCase() +
                this.state.g.toString(16).toUpperCase() +
                this.state.b.toString(16).toUpperCase()
              : this.state.hex}
          </div>
          <Button
            onClick={() => {
              navigator.clipboard.writeText(
                this.state.format === "1"
                  ? this.state.r.toString(16).toUpperCase() +
                      this.state.g.toString(16).toUpperCase() +
                      this.state.b.toString(16).toUpperCase()
                  : this.state.hex
              );
              this.setState({ copiedHEX: true });
            }}
            className="col-sm-2 btn btn-outline-light"
          >
            <FontAwesomeIcon icon={faClipboard}></FontAwesomeIcon>
          </Button>
        </div>
      </div>
    );
  }

  setColorInfo() {
    this.setState({ colInfoSet: true });
  }

  generate_rgb_elem() {
    return (
      <FormGroup className="m-3">
        <Form.Label className="w-100 text-center p-0">R,G,B FORMAT</Form.Label>
        <div className="col-sm row">
          <div className="col-sm col-border-r bg-danger m-1 p-0">
            <Form.Control
              className="col-sm form-control m-auto mt-1 mb-1"
              type="number"
              min={0}
              max={255}
              onChange={this.stateColor_r}
            />
          </div>
          <div className="col-sm col-border-g bg-success m-1 p-0">
            <Form.Control
              className="form-control m-auto mt-1 mb-1"
              type="number"
              min={0}
              max={255}
              onChange={this.stateColor_g}
            />
          </div>
          <div className="col-sm col-border-b bg-primary m-1 p-0">
            <Form.Control
              className="form-control m-auto mt-1 mb-1"
              type="number"
              min={0}
              max={255}
              onChange={this.stateColor_b}
            />
          </div>
        </div>
      </FormGroup>
    );
  }

  render() {
    return (
      <div>
        <NavigatorMenu />
        <Container className="w-75 p-5">
          <FormGroup>
            {this.state.format === "0"
              ? this.generate_hex_elem()
              : this.generate_rgb_elem()}
            <div className="m-3">
              <Form.Control as="select" onChange={this.stateFormat}>
                <option value="0">HEX</option>
                <option value="1">RGB</option>
              </Form.Control>
            </div>
          </FormGroup>
          {this.allColorInfo()}
        </Container>
      </div>
    );
  }
}

export default ColorsTool;
