import http from "../http";
import UserData from "../types/user.type";

class UserDataService {
  tryLogin(data: UserData) {
    return http.post<UserData>("/admin-panel", data);
  }
}

const UserDataServicer = new UserDataService();
export default UserDataServicer;
