import TutorialDataService from "../services/art.service";
import { Link, useParams } from "react-router-dom";
import ITutorialData from "../types/article.type";
import parse from "html-react-parser";
import "./threadLists.css";
import { useState, useEffect } from "react";
import Navigator from "./Navigator";
import E404 from "./E404";
import Footer from "./Footer";
import Banners from "./Banners";

function showTags(tags: Array<string>) {
  if (tags !== null) {
    return tags.map((tag: string, key: number) => {
      return (
        <div className="small badge m-1 p-1 text-white bg-dark" key={key}>
          #{tag}
        </div>
      );
    });
  }
  return "";
}

/**
 *
 * @brief Generate page with Articles list
 * @param Arts Articles list from database
 * @returns Page with articles short list
 */
function displayAllArts(Arts: ITutorialData[]) {
  return (
    <div>
      <Navigator />
      {Arts.map((currentArts: ITutorialData, index: number) => (
        <div className="card p-3 m-3 shadow-sm" key={index}>
          <div className="card-body">
            <h5 className="card-title">{currentArts.title}</h5>
            <div className="card-text">
              {parse(currentArts.description as string)}
            </div>
            <div className="card-text">
              {new Date(currentArts.createdAt).toLocaleDateString("pl-PL")}
            </div>
          </div>
          <div className="d-inline-flex">
            {showTags(JSON.parse(currentArts.TAGS))}
          </div>
          <Link
            to={`/article/${currentArts.url}`}
            className="thread-onlist-link"
          >
            Czytaj więcej...
          </Link>
        </div>
      ))}
    </div>
  );
}

function loadingProgress() {
  return (
    <div className="loader-container">
      <div className="spinner"></div>
    </div>
  );
}

export default function SearchResult() {
  const [artData, setArtData] = useState<ITutorialData[]>([]);
  const [currentArts, setCurrentArts] = useState<ITutorialData[]>([]);
  const [loading, setLoading] = useState(true);
  const postPerPage = 10;
  const [postCount, setPostsCount] = useState(-1);
  const { title } = useParams();
  useEffect(() => {
    const getArt = async () => {
      TutorialDataService.findByTitle(title !== undefined ? title : "")
        .then((response: any) => {
          setArtData(response.data);
          setPostsCount(response.data.length);
          setLoading(true);
          setCurrentArts(artData.slice(0, postPerPage));
        })
        .catch((e: Error) => {
          setLoading(false);
        });
    };
    getArt();
  }, [postPerPage, postCount, loading, title, artData]);
  if (postCount === -1) {
    return loadingProgress();
  } else if (postCount === 0) {
    return (
      <div>
        <Navigator />
        <div className="card">
          <h2 className="p-5 m-5 text-center text-error">
            UPS ..... NIESTETY NIC NIE ZNALAZŁEM
          </h2>
        </div>
        <Banners />
        <Footer />
      </div>
    );
  }
  return loading === false ? (
    <E404 />
  ) : (
    <div>
      {displayAllArts(currentArts)}
      <Banners />
      <Footer />
    </div>
  );
}
