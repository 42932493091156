import { ChangeEvent } from "react";
import TutorialDataService from "../services/art.service";
import { Link } from "react-router-dom";
import ITutorialData from "../types/article.type";
import parse from "html-react-parser";
import Footer from "./Footer";
import React from "react";
import { Helmet } from "react-helmet";
import NavigatorMenu from "./Navigator";
import { motion } from "framer-motion";
import Banners from "./Banners";
import ReactPaginate from "react-paginate";
import { IconContext } from "react-icons";
import { AiFillLeftSquare, AiFillRightSquare } from "react-icons/ai";

type State = {
  arts: Array<ITutorialData>;
  complete: Array<ITutorialData>;
  artsPage: Array<ITutorialData>;
  currentIndex: number;
  searchTitle: string;
  postsPerPage: number;
  pageCount: number;
  page: number;
  postsCount: number;
  showPage: number;
  itemOffset: number;
};

class ArticlesList extends React.Component<ITutorialData, State> {
  constructor(props: ITutorialData) {
    super(props);
    this.onChangeSearchTitle = this.onChangeSearchTitle.bind(this);
    this.retrieveTutorials = this.retrieveTutorials.bind(this);

    this.state = {
      arts: [],
      complete: [],
      currentIndex: -1,
      searchTitle: "",
      page: 1,
      pageCount: 0,
      postsCount: 0,
      postsPerPage: 5,
      showPage: -1,
      itemOffset: 0,
      artsPage: [],
    };
  }

  componentDidMount() {
    window.scrollTo({ top: 0, behavior: "smooth" });
    this.retrieveTutorials();
  }

  onChangeSearchTitle(e: ChangeEvent<HTMLInputElement>) {
    const searchTitle = e.target.value;

    this.setState({
      searchTitle: searchTitle,
    });
  }

  async retrieveTutorials() {
    await TutorialDataService.getAll()
      .then((response: any) => {
        this.setState({
          arts: response.data,
          postsCount: response.data.length,
          pageCount: Math.floor(
            (response.data.length + 4) / this.state.postsPerPage
          ),
          artsPage: response.data.slice(0, this.state.postsPerPage),
          showPage: 1,
        });
      })
      .catch((e: Error) => {});
  }

  searchTitle() {
    TutorialDataService.findByTitle(this.state.searchTitle)
      .then((response: any) => {
        this.setState({
          arts: response.data,
          postsCount: response.data.length,
          pageCount: Math.floor(response.data.length / this.state.postsPerPage),
          artsPage: response.data.slice(0, this.state.postsPerPage),
          showPage: 1,
        });
      })
      .catch((e: Error) => {});
  }

  createMetaSection() {
    return (
      <Helmet>
        <title>Computer science world - moblaza.com</title>
        <meta
          name={
            "Języki programowanie - kompilowane, technologie webowe, świat Linuksa, Unix, ecosystem ..."
          }
          content={
            "C++, TYPESCRIPT, JAVASCRIPT, PHP, MYSQL, MSSQL, REACT, QT, STL, BOOST"
          }
        />
      </Helmet>
    );
  }

  createSearchElement() {
    return (
      <div className="row my-3">
        <div className="col-md-6">
          <div className="form-outline">
            <input
              className="text-center form-control w-25 d-block m-lg-auto m-xl-auto"
              id="search-title"
              type="text"
              placeholder="Szukaj coś"
              onChange={this.onChangeSearchTitle}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  this.searchTitle();
                }
              }}
            />
          </div>
          <div className="mt-3 mb-3">
            <button
              className="btn btn-success d-block m-lg-auto m-xl-auto"
              onClick={this.searchTitle}
            >
              Szukaj
            </button>
          </div>
        </div>
      </div>
    );
  }

  showTags(tags: Array<string>) {
    if (tags !== null) {
      return tags.map((tag: string, key: number) => {
        return (
          <div className="small badge m-1 p-1 text-white bg-dark" key={key}>
            #{tag}
          </div>
        );
      });
    }
    return "";
  }

  currentArts() {
    return (
      <div>
        {this.state.artsPage &&
          this.state.artsPage.map(
            (currentArt: ITutorialData, index: number) => (
              <div className="card p-3 m-3 shadow-sm" key={index}>
                <div className="card-body">
                  <h5 className="card-title">{currentArt.title}</h5>
                  <div className="card-text">
                    {parse(currentArt.description as string)}
                  </div>
                  <div className="card-text">
                    {new Date(currentArt.createdAt).toLocaleDateString("pl-PL")}
                  </div>
                </div>
                <div className="d-inline-flex">
                  {this.showTags(JSON.parse(currentArt.TAGS))}
                </div>
                <Link
                  to={`/article/${currentArt.url}`}
                  className="thread-onlist-link"
                >
                  Czytaj więcej...
                </Link>
              </div>
            )
          )}
      </div>
    );
  }

  paginatedArts(event: { selected: number }) {
    this.setState({
      artsPage: this.state.arts.slice(
        event.selected * this.state.postsPerPage,
        event.selected * this.state.postsPerPage + this.state.postsPerPage
      ),
    });
  }

  displayAllArts() {
    return (
      <div>
        <NavigatorMenu />
        {this.currentArts()}
        <ReactPaginate
          breakLabel="..."
          onPageChange={(event) => {
            this.paginatedArts(event);
          }}
          pageRangeDisplayed={5}
          pageCount={this.state.pageCount}
          renderOnZeroPageCount={null}
          containerClassName={"pagination"}
          activeClassName={"page-active"}
          pageClassName={"page-item"}
          previousLabel={
            <IconContext.Provider value={{ color: "#B8C1CC", size: "40px" }}>
              <AiFillLeftSquare />
            </IconContext.Provider>
          }
          nextLabel={
            <IconContext.Provider value={{ color: "#B8C1CC", size: "40px" }}>
              <AiFillRightSquare />
            </IconContext.Provider>
          }
        />
      </div>
    );
  }

  loadingProgress() {
    return (
      <div className="loader-container">
        <div className="spinner"></div>
      </div>
    );
  }

  render() {
    this.createMetaSection();
    return this.state.showPage === 1 ? (
      <div className="container">
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.3 }}
        >
          {this.displayAllArts()}
          <Banners />
          <Footer />
        </motion.div>
      </div>
    ) : (
      this.loadingProgress()
    );
  }
}

export default ArticlesList;
