import { AxiosResponse } from "axios";
import http from "../http";
import IFooterData from "src/types/footer.type";

class FooterDataService {
  async getFooterMenu1() {
    return await http.get<Array<IFooterData>>(`/footerMenu1/`);
  }
  async getFooterMenu2() {
    return await http.get<Array<IFooterData>>(`/footerMenu2/`);
  }
  async setFooterMenus1(data: IFooterData) {
    console.log(data);
    return await http.post<IFooterData>("/footerMenu1", data);
  }
  async setFooterMenus2(data: IFooterData) {
    console.log(data);
    return await http.post<IFooterData>("/footerMenu2", data);
  }
}

const footerDataService = new FooterDataService();
export default footerDataService;
