import axios from "axios";
import config from "./config";

const http = axios.create({
  baseURL: `${config.api_url}/api`,
  maxBodyLength: Infinity,
  maxContentLength: Infinity,
  headers: {
    "content-type": "application/json",
  },
});

export default http;
