import "jquery/dist/jquery.min.js";
import "bootstrap/dist/js/bootstrap.min.js";
import "highlight.js/styles/monokai-sublime.css";
import {
  FC,
  useState,
  useRef,
  useMemo,
  ReactElement,
  RefObject,
  KeyboardEvent,
  MouseEvent,
} from "react";
import TutorialDataService from "../services/art.service";
import IArticleData from "../types/article.type";
import ReactQuill from "react-quill";
import EdTags from "./EdTags";
import "react-quill/dist/quill.snow.css";
import "bootstrap/dist/css/bootstrap.css";
import hljs from "highlight.js";
import Quill from "quill";
import MagicUrl from "quill-magic-url";
import config from "../config";
Quill.register("modules/magicUrl", MagicUrl);
hljs.configure({
  languages: ["javascript", "c++", "bash", "typescript", "php", "html"],
});

async function addArtToDatabase(
  content: any,
  title: any,
  summary: string,
  tags: string,
  url: string
): Promise<number> {
  const data: IArticleData = {
    title: title,
    content: content,
    description: summary,
    TAGS: tags,
    url: url,
  };
  return await TutorialDataService.create(data)
    .then((res: any) => {
      return 2;
    })
    .catch((err: any) => {
      return -1;
    });
}

const createDescModule = () => {
  const toolbar = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link"],
      ["clean"],
    ],
  };
  return toolbar;
};

const imageHandler = async (val: any, quillRef: RefObject<ReactQuill>) => {
  const input = document.createElement("input");
  input.setAttribute("type", "file");
  input.setAttribute("accept", "image/*");
  input.click();
  input.onchange = async () => {
    var file: any = input && input.files ? input.files[0] : null;
    var formData = new FormData();
    formData.append("image", file);
    if (quillRef.current !== null) {
      let quillObj = quillRef.current.getEditor();
      fetch(`${config.api_url}/api/images-upload/`, {
        method: "POST",
        body: formData,
      })
        .then((response) => {
          let range: any;
          range = quillObj.getSelection();
          if (range !== null) {
            quillObj.insertEmbed(
              range,
              "image",
              `${config.api_url}/uploads/` + file.name
            );
          }
          quillObj.setSelection(range.index + 1);
        })
        .catch((error) => {});
    }
  };
};

const createDescFormats = () => {
  const formats_desc = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "align",
    "strike",
    "script",
    "blockquote",
    "background",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "video",
    "color",
    "code-block",
  ];
  return formats_desc;
};

const renderMainContainer = () => {
  const container = [
    [{ font: [] }, { size: [] }, { header: [1, 2, 3, 4, 5, 6] }],
    ["bold", "italic", "underline", "strike"],
    [{ color: [] }, { background: [] }],
    [{ script: "sub" }, { script: "super" }],
    [{ header: 1 }, { header: 2 }, "blockquote", "code-block"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    [
      { align: "" },
      { align: "center" },
      { align: "right" },
      { align: "justify" },
    ],
    ["link", "image", "video"],
    [{ script: "sub" }, { script: "super" }],
    ["clean"],
    ["code-block"],
  ];
  return container;
};

async function check_url_in_db(url: string): Promise<boolean> {
  let result: boolean = false;
  await TutorialDataService.findUrl(url)
    .then((res: any) => {
      console.log(res.data.length);
      if (res.data.length === 0) {
        result = true;
      } else if (res.data.length > 0) {
        result = false;
      }
    })
    .catch((err: any) => {
      result = false;
      console.log(err);
    });
  console.log(result);
  return result;
}

const AddArt: FC = (): ReactElement => {
  const quillRef = useRef<ReactQuill>(null);
  const [title, setTitle] = useState("");
  const [url, setUrl] = useState("");
  const [urlStatus, setUrlStatus] = useState(0);
  const [summary, setSummary] = useState("");
  const [content, setContent] = useState("");
  const [is_added_true, setAdded] = useState(0);
  const modules_desc = createDescModule();

  const modules = useMemo(
    () => ({
      syntax: true,
      magicUrl: true,
      toolbar: {
        container: renderMainContainer(),
        handlers: {
          image: (val: any) => imageHandler(val, quillRef),
        },
      },
    }),
    []
  );

  const formats_desc = createDescFormats();

  return (
    <div className="container">
      <div className="submit-form">
        <div className="editor-r">
          <input
            className="form-control"
            type="title"
            placeholder="Title"
            onChange={(event) => setTitle(event.target.value)}
            value={title}
          />
          <p>Description</p>
          <input
            className="form-control"
            type="title"
            placeholder="Url"
            onKeyDown={(event: KeyboardEvent<HTMLInputElement>) => {
              setUrl(event.currentTarget.value);
            }}
          />
          <input
            type="button"
            id="url-check-btn"
            className="d-none"
            onClick={(event: MouseEvent<HTMLInputElement>) => {
              setUrlStatus(0);
              check_url_in_db(url).then((result: any) => {
                if (result === true) {
                  setUrlStatus(1);
                } else {
                  setUrlStatus(2);
                }
              });
            }}
          />
          <label htmlFor="url-check-btn" className="btn btn-success m-2">
            Sprawdz unikalny URL
          </label>
          {urlStatus === 1 ? (
            <div className="text-success">URL POPRAWNY I UNIKALNY</div>
          ) : urlStatus === 2 ? (
            <div className="text-danger">URL NIEPOPRAWNY LUB WYSTĘPUJE</div>
          ) : (
            ""
          )}
          <ReactQuill
            theme="snow"
            modules={modules_desc}
            formats={formats_desc}
            onChange={(value) => setSummary(value)}
            value={summary}
          />
          <p>Body:</p>
          <ReactQuill
            theme="snow"
            modules={modules}
            formats={formats_desc}
            onChange={(value) => setContent(value)}
            value={content}
            ref={quillRef}
          />
          <EdTags tags={[]} />
          <div className="mt-2 mb-2">
            <button
              className="btn btn-success d-block w-25 m-lg-auto m-xl-auto"
              onClick={(e) =>
                addArtToDatabase(
                  content,
                  title,
                  summary,
                  localStorage.getItem("TAGS") as string,
                  url
                ).then((res) => {
                  setContent("");
                  setTitle("");
                  setSummary("");
                  setAdded(2);
                })
              }
            >
              Dodaj artykuł
            </button>
          </div>
          {is_added_true === 2 ? (
            <div className="alert alert-success text-center">
              Art added cuccessfull
            </div>
          ) : is_added_true === -1 ? (
            <div className="false">
              <p className="text-center">Art not added</p>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

export default AddArt;
