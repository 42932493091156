import "bootstrap/dist/css/bootstrap.css";
import { Component } from "react";
import { Routes, Route } from "react-router-dom";
import ArticlesList from "./components/ArtsListComponent";
import AdminPanel from "./AdminPanel/AdminPanel";
import ShowArt from "./components/ShowArt";
import E404 from "./components/E404";
import SearchResult from "./components/SearchResult";
import BannersSection from "./AdminPanel/BannersSection";
import DragEndDropMenuConfig from "./components/MenuConfig";
import CategoryGetComponent from "./components/ShowByCategory";
import ArtsList from "./AdminPanel/ArtsList";
import EditArt from "./AdminPanel/EditArt";
import ColorsTool from "./components/ColorsTool";
import AboutPage from "./components/AboutPage";
import EditAboutPage from "./AdminPanel/EditAbout";
import FooterConfig from "./AdminPanel/FooterConfig";

class App extends Component {
  constructor(props: any) {
    window.scrollTo({ top: 0, behavior: "smooth" });
    super(props);
  }
  render() {
    return (
      <Routes>
        <Route path="/" element={<ArticlesList />} />
        <Route path="/articles" element={<ArticlesList />} />
        <Route path="/article/:id" element={<ShowArt />} />
        <Route path="/category/:type" element={<CategoryGetComponent />} />
        <Route path="/admin-panel" element={<AdminPanel />} />
        <Route path="/search-arts-by-title/:title" element={<SearchResult />} />
        <Route path="/search-arts-by-title/" element={<SearchResult />} />
        <Route path="/banner-section" element={<BannersSection />} />
        <Route path="/arts-section" element={<ArtsList />} />
        <Route path="/menu-config" element={<DragEndDropMenuConfig />} />
        <Route path="/edit-art/:id" element={<EditArt />} />
        <Route path="/colors-tools" element={<ColorsTool />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/footer-menu" element={<FooterConfig />} />
        <Route path="/edit-about" element={<EditAboutPage />} />
        <Route path="*" element={<E404 />} />
      </Routes>
    );
  }
}

export default App;
