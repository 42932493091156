import React from "react";
import IArticleData from "src/types/article.type";
import artsDataService from "src/services/art.service";
import AdminNavigator from "./AdminNavigator";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { faSlackHash } from "@fortawesome/free-brands-svg-icons";

interface States {
  arts: Array<IArticleData>;
}

interface Props {}

class ArtsList extends React.Component<Props, States> {
  constructor(props: any) {
    super(props);
    this.state = {
      arts: [],
    };
  }
  componentDidMount(): void {
    artsDataService.getAllforPanel().then((result: any) => {
      this.setState({ arts: [...result.data] });
    });
  }

  showDisableConfirmation(id_elem: number) {
    const data: IArticleData = {
      published:
        this.state.arts.find((elem: IArticleData) => elem.id === id_elem)
          ?.published === true
          ? false
          : true,
    };
    artsDataService
      .update_visiblility(data, id_elem)
      .then((res) => {
        console.log(res);
        artsDataService.getAllforPanel().then((result: any) => {
          this.setState({ arts: [...result.data] });
        });
      })
      .catch((e: any) => {
        console.log(e);
      });
  }

  shortArts() {
    return (
      <div>
        {this.state.arts.map((art: IArticleData, index: number) => {
          return (
            <div key={index}>
              <div className="row p-2 m-2 bg-light">
                <div className="col-1">{art.id}</div>
                <div className="col">{art.title}</div>
                <div className="col">{art.url}</div>
                <div className="col-1">
                  <Link to={`/edit-art/${art.id}`} className="col-1">
                    <FontAwesomeIcon
                      className="me-3"
                      icon={faEdit}
                    ></FontAwesomeIcon>
                  </Link>
                </div>
                <div className="col-1">
                  <Link
                    to={""}
                    className="col-1"
                    onClick={() => this.showDisableConfirmation(art.id)}
                  >
                    <FontAwesomeIcon
                      className="me-3"
                      icon={faSlackHash}
                    ></FontAwesomeIcon>
                  </Link>
                </div>
              </div>
              {art.published === true ? (
                <div className="row p-2 m-2 bg-warning">ARTYKUŁ WYŁACZONY</div>
              ) : (
                ""
              )}
            </div>
          );
        })}
      </div>
    );
  }
  render() {
    return (
      <div>
        <AdminNavigator />
        <div className="container">{this.shortArts()}</div>
      </div>
    );
  }
}

export default ArtsList;
