import TutorialDataService from "../services/art.service";
import IBannerData from "src/types/baner.type";
import React from "react";
import "../style.css";

interface Props {}
interface States {
  banners: Array<IBannerData>;
}

class Banners extends React.Component<Props, States> {
  constructor(props: any) {
    super(props);
    this.state = {
      banners: [],
    };
  }
  async componentDidMount() {
    await TutorialDataService.getBanners()
      .then((response: any) => {
        this.setState({ banners: response.data });
      })
      .catch((e) => {});
  }
  render() {
    return (
      <div className="row m-3">
        {this.state.banners.map((baner: IBannerData, index: number) => {
          return (
            <div className="col" key={index}>
              <a href={baner.banner_url} title={baner.title}>
                <img
                  src={baner.url}
                  alt={baner.title}
                  loading="eager"
                  className="banner-img"
                />
              </a>
            </div>
          );
        })}
      </div>
    );
  }
}

export default Banners;
