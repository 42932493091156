import "bootstrap/dist/css/bootstrap.css";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGithub } from "@fortawesome/free-brands-svg-icons";
import { faFacebook } from "@fortawesome/free-brands-svg-icons";
import { faGoogle } from "@fortawesome/free-brands-svg-icons";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { faTwitter } from "@fortawesome/free-brands-svg-icons";
import { faGem, faHome, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import ColorsTool from "./ColorsTool";
import footerDataService from "src/services/footer.service";
import React, { useEffect } from "react";
import IFooterElem from "../AdminPanel/FooterElem";

function ScrollToTopButton() {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
}

export default function Footer() {
  const [footerMenu1, setFooterMenu1] = React.useState<Array<IFooterElem>>([]);
  const [footerMenu2, setFooterMenu2] = React.useState<Array<IFooterElem>>([]);
  const [loading, setLoading] = React.useState(false);
  useEffect(() => {
    if (loading == true) {
      return;
    }
    const getFooters = async () => {
      const data = await footerDataService
        .getFooterMenu1()
        .then((data: any) => {
          setFooterMenu1(JSON.parse(data.data["footer_menu1"]));
          console.log(JSON.parse(data.data["footer_menu1"]));
          setFooterMenu2(JSON.parse(data.data["footer_menu2"]));
          setLoading(true);
        })
        .catch((err: any) => {
          console.log("Exception: " + err);
        });
    };
    getFooters();
  }, [footerMenu1, footerMenu2]);
  return (
    <footer className="text-center text-lg-start bg-light text-muted">
      <section className="d-flex justify-content-center justify-content-lg-between p-4 border-bottom">
        <div className="m-lg-auto m-xl-auto d-block">
          <a
            href="https://www.facebook.com/moblaza/"
            className="me-4 text-reset"
          >
            <FontAwesomeIcon icon={faFacebook}></FontAwesomeIcon>
          </a>
          <a href="https://twitter.com/moblaza_com" className="me-4 text-reset">
            <FontAwesomeIcon icon={faTwitter}></FontAwesomeIcon>
          </a>
          <a
            href="https://developers.google.com/profile/u/100075172205596788798/edit"
            className="me-4 text-reset"
          >
            <FontAwesomeIcon icon={faGoogle}></FontAwesomeIcon>
          </a>
          <a
            href="https://www.linkedin.com/in/maciej-obłaza/"
            className="me-4 text-reset"
          >
            <FontAwesomeIcon icon={faLinkedin}></FontAwesomeIcon>
          </a>
          <a href="https://github.com/Mac1ek" className="me-4 text-reset">
            <FontAwesomeIcon icon={faGithub}></FontAwesomeIcon>
          </a>
        </div>
      </section>
      <section className="">
        <div className="container text-center text-md-start mt-5">
          <div className="row mt-3">
            <div className="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">
              <FontAwesomeIcon icon={faGem} className="p-1"></FontAwesomeIcon>
              <h6 className="text-uppercase fw-bold mb-4">DEV-DATA.PL</h6>
              <p>
                Ciekawostki z programistycznego świata. Standardy, manuale,
                wskazówki ...
              </p>
              <strong>
                <p>NIE BOT, NIE SPAMER, ZWYKŁY LAMER ...</p>
              </strong>
            </div>

            <div className="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">
              {footerMenu1.map((item, index) => (
                <Link
                  className="text-reset"
                  to={item.link}
                  key={index}
                  onClick={ScrollToTopButton}
                >
                  <p>{item.name}</p>
                </Link>
              ))}
            </div>
            <div className="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">
              {footerMenu2.map((item, index) => (
                <Link
                  className="text-reset"
                  to={item.link}
                  key={index}
                  onClick={ScrollToTopButton}
                >
                  <p>{item.name}</p>
                </Link>
              ))}
            </div>

            <div className="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
              <h6 className="text-uppercase fw-bold mb-4">Contact</h6>
              <FontAwesomeIcon className="me-3" icon={faHome}></FontAwesomeIcon>
              <Link to={"https://m.me/moblaza"}>
                <p>m.me/moblaza</p>
              </Link>
              <FontAwesomeIcon
                className="me-3"
                icon={faEnvelope}
              ></FontAwesomeIcon>
              <Link to="mailto:moblaza@gmail.com">
                <p>moblaza@gmail.com</p>
              </Link>
            </div>
          </div>
        </div>
      </section>
      <div className="text-center p-4">© 2023 Copyright: Maciej Obłaza</div>
    </footer>
  );
}
