import React from "react";
//import SortableTree from 'react-sortable-tree';

class DragEndDropMenuConfig extends React.Component {
  constructor(props: any) {
    super(props);
    this.state = {};
  }
  render() {
    return <></>;
  }
}

export default DragEndDropMenuConfig;
