import Footer from "./Footer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBackward } from "@fortawesome/free-solid-svg-icons";
import guru from "../assets/images/guru.png";

export default function E404() {
  return (
    <div className="row text-center">
      <h2>404</h2>
      <div className="col-md-8 mx-auto">
        <img src={guru} alt="logo" />
        <div className="text-center d-block m-2 p-2">
          <div
            onClick={(e) => {
              window.history.back();
            }}
          >
            <FontAwesomeIcon
              icon={faBackward}
              fontSize={32}
              className="IconFontAwesome"
            />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
