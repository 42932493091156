import React from "react";
import TutorialDataService from "../services/art.service";
import Footer from "../components/Footer";
import "bootstrap/dist/css/bootstrap.css";
import "jquery/dist/jquery.min.js";
import "bootstrap/dist/js/bootstrap.min.js";
import "../style.css";
import { motion } from "framer-motion";
import AdminPanel from "./AdminPanel";
import AdminNavigator from "./AdminNavigator";
import config from "../config";

interface BannersStates {
  banner1_value: string;
  banner2_value: string;
  banner3_value: string;
  banner1_title: string;
  banner2_title: string;
  banner3_title: string;
  banner1_input: any;
  banner2_input: any;
  banner3_input: any;
  banner1_up: boolean;
  banner2_up: boolean;
  banner3_up: boolean;
  banner1_img_name: string;
  banner2_img_name: string;
  banner3_img_name: string;
  banner1_url: string;
  banner2_url: string;
  banner3_url: string;
  token: string;
}

class BannersSection extends React.Component<{}, BannersStates> {
  constructor(props: any) {
    super(props);

    this.state = {
      banner1_value: "",
      banner2_value: "",
      banner3_value: "",
      banner1_title: "",
      banner2_title: "",
      banner3_title: "",
      banner1_input: {},
      banner2_input: {},
      banner3_input: {},
      banner1_img_name: "",
      banner2_img_name: "",
      banner3_img_name: "",
      banner1_up: false,
      banner2_up: false,
      banner3_up: false,
      banner1_url: "",
      banner2_url: "",
      banner3_url: "",
      token:
        localStorage.getItem("token") === null
          ? ""
          : (localStorage.getItem("token") as string),
    };
  }

  async componentDidMount() {
    await TutorialDataService.getBanners().then((response: any) => {
      if (response.data.length > 0) {
        this.setState({
          banner1_title: response.data[0].title,
          banner1_value: response.data[0].url,
          banner2_title: response.data[1].title,
          banner2_value: response.data[1].url,
          banner3_title: response.data[2].title,
          banner3_value: response.data[2].url,
          banner1_url: response.data[0].banner_url,
          banner2_url: response.data[1].banner_url,
          banner3_url: response.data[2].banner_url,
          banner1_up: true,
          banner2_up: true,
          banner3_up: true,
        });
      }
    });
  }

  async updateBannersData(index: number) {
    switch (index) {
      case 1:
        await fetch(`${config.api_url}/api/banners-update/${index}`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            title: this.state.banner1_title,
            banner_url: this.state.banner1_url,
          }),
        }).then((response: Response) => {
          console.log(response);
        });
        break;
      case 2:
        await fetch(`${config.api_url}/api/banners-update/${index}`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            title: this.state.banner2_title,
            banner_url: this.state.banner2_url,
          }),
        }).then((response: Response) => {
          console.log(response);
        });
        break;
      case 3:
        await fetch(`${config.api_url}/api/banners-update/3`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            title: this.state.banner3_title,
            banner_url: this.state.banner3_url,
          }),
        }).then((response: Response) => {
          console.log(response);
        });
        break;
    }
  }

  async postBannersData(index: number) {
    switch (index) {
      case 1:
        await fetch(`${config.api_url}/api/banners-update/${index}`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            title: this.state.banner1_title,
            url: `${config.api_url}/banners/${index}/${this.state.banner1_value}`,
            img_link: `${this.state.banner1_value}`,
            img_name: this.state.banner1_img_name,
            banner_url: this.state.banner1_url,
          }),
        }).then((response: Response) => {
          console.log(response);
        });
        break;
      case 2:
        await fetch(`${config.api_url}/api/banners-update/${index}`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            title: this.state.banner2_title,
            url: `${config.api_url}/banners/${index}/${this.state.banner2_value}`,
            img_link: `${this.state.banner2_value}`,
            img_name: this.state.banner2_img_name,
            banner_url: this.state.banner2_url,
          }),
        }).then((response: Response) => {
          console.log(response);
        });
        break;
      case 3:
        await fetch(`${config.api_url}/api/banners-update/3`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            title: this.state.banner3_title,
            url: `${config.api_url}/banners/${index}/${this.state.banner3_value}`,
            img_link: `${this.state.banner3_value}`,
            img_name: this.state.banner3_img_name,
            banner_url: this.state.banner3_url,
          }),
        }).then((response: Response) => {
          console.log(response);
        });
        break;
    }
  }

  async bannerImgUpload(val: HTMLInputElement, ban_num: number) {
    var file: any = val && val.files ? val.files[0] : null;
    if (file === null) {
      await this.updateBannersData(ban_num);
      return;
    }
    var formData = new FormData();
    formData.append("image", file);
    await fetch(`${config.api_url}/api/banners-upload/${ban_num}`, {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        switch (ban_num) {
          case 1:
            this.setState({
              banner1_value: `${config.api_url}/banners/${ban_num}/${file.name}`,
              banner1_img_name: file.name,
              banner1_up: true,
            });
            this.postBannersData(1);
            break;
          case 2:
            this.setState({
              banner2_value: `${config.api_url}/banners/${ban_num}/${file.name}`,
              banner2_img_name: file.name,
              banner2_up: true,
            });
            this.postBannersData(2);
            break;
          case 3:
            this.setState({
              banner3_value: `${config.api_url}/banners/${ban_num}/${file.name}`,
              banner3_img_name: file.name,
              banner3_up: true,
            });
            this.postBannersData(3);
            break;
        }
      })
      .catch((error) => {});
  }

  processBannerTitle(e: React.ChangeEvent<HTMLInputElement>, ban_num: number) {
    switch (ban_num) {
      case 1:
        this.setState({ banner1_title: e.target.value });
        break;
      case 2:
        this.setState({ banner2_title: e.target.value });
        break;
      case 3:
        this.setState({ banner3_title: e.target.value });
        break;
    }
  }

  saveBanners() {
    if (this.state.banner1_value !== "") {
      this.bannerImgUpload(this.state.banner1_input, 1);
    }
    if (this.state.banner2_value !== "") {
      this.bannerImgUpload(this.state.banner2_input, 2);
    }
    if (this.state.banner3_value !== "") {
      this.bannerImgUpload(this.state.banner3_input, 3);
    }
  }

  processBannerUrl(e: React.ChangeEvent<HTMLInputElement>, ban_num: number) {
    console.log(e.target.value);
    switch (ban_num) {
      case 1:
        this.setState({
          banner1_url: e.target.value,
        });
        break;
      case 2:
        this.setState({
          banner2_url: e.target.value,
        });
        break;
      case 3:
        this.setState({
          banner3_url: e.target.value,
        });
    }
  }

  processBannerImages(e: React.ChangeEvent<HTMLInputElement>, ban_num: number) {
    switch (ban_num) {
      case 1:
        this.setState({
          banner1_value: e.target.files === null ? "" : e.target.files[0].name,
          banner1_input: e.target,
        });
        break;
      case 2:
        this.setState({
          banner2_value: e.target.files === null ? "" : e.target.files[0].name,
          banner2_input: e.target,
        });
        break;
      case 3:
        this.setState({
          banner3_value: e.target.files === null ? "" : e.target.files[0].name,
          banner3_input: e.target,
        });
    }
  }

  showBanersConfig() {
    return (
      <div className="container p-5 d-grid">
        <div className="row">
          <input
            className="mt-2 mb-2"
            type="text"
            placeholder="Set title"
            onChange={(e) => this.processBannerTitle(e, 1)}
            alt="Banner 1 title"
            value={this.state.banner1_title}
          />
          <label htmlFor="banner1" className="btn btn-success">
            Wybierz zdjęcie dla banera nr 1
          </label>
          <input
            className="d-none"
            id="banner1"
            type="file"
            placeholder="Banner nr. 1"
            onChange={(e) => this.processBannerImages(e, 1)}
          />
          <input
            className="mt-2 mb-2"
            id="url1"
            type="text"
            placeholder="Set URL"
            alt="Banner 1 url"
            value={this.state.banner1_url}
            onChange={(e) => this.processBannerUrl(e, 1)}
          />
          <label htmlFor="url1" className="btn btn-success">
            Podaj adres URL powiązany z banerem
          </label>
          {this.state.banner1_value !== "" ? (
            <div className="fade-info bg-light">
              Zostanie Załadowana grafika:{" "}
              <div className="text-success">{this.state.banner1_value}</div>
            </div>
          ) : (
            ""
          )}
          {this.state.banner1_up ? (
            <img
              className="banner p-0"
              src={this.state.banner1_value}
              alt="banner 1"
            />
          ) : (
            ""
          )}
        </div>
        <div className="row">
          <input
            className="mt-2 mb-2"
            type="text"
            placeholder="Set title"
            onChange={(e) => this.processBannerTitle(e, 2)}
            alt="Banner 2 title"
            value={this.state.banner2_title}
          />
          <label htmlFor="banner2" className="btn btn-success">
            Wybierz zdjęcie dla banera 2
          </label>
          <input
            className="d-none"
            id="banner2"
            type="file"
            placeholder="Banner nr. 2"
            onChange={(e) => this.processBannerImages(e, 2)}
          />
          <input
            className="mt-2 mb-2"
            id="url2"
            type="text"
            placeholder="Set URL"
            alt="banner 1 url"
            value={this.state.banner2_url}
            onChange={(e) => {
              this.processBannerUrl(e, 2);
            }}
          />
          <label htmlFor="url2" className="btn btn-success">
            PODAJ ADRES URL POWIĄZANY Z BANEREM
          </label>
          {this.state.banner2_value !== "" ? (
            <div className="fade-info bg-light">
              Zostanie Załadowana grafika:{" "}
              <div className="text-success">{this.state.banner2_value}</div>
            </div>
          ) : (
            ""
          )}
          {this.state.banner2_up ? (
            <img
              className="banner p-0"
              src={this.state.banner2_value}
              alt="banner 1"
            />
          ) : (
            ""
          )}
        </div>
        <div className="row">
          <input
            className="mt-2 mb-2"
            type="text"
            placeholder="Set title"
            onChange={(e) => this.processBannerTitle(e, 3)}
            alt="Banner 3 title"
            value={this.state.banner3_title}
          />
          <label htmlFor="banner3" className="btn btn-success">
            Wybierz zdjęcie dla banera 3
          </label>
          <input
            className="d-none"
            id="banner3"
            type="file"
            placeholder="Banner nr. 3"
            onChange={(e) => this.processBannerImages(e, 3)}
          />
          <input
            id="url3"
            type="text"
            placeholder="Set URL"
            alt="Banner 3 url"
            className="mt-2 mb-2"
            value={this.state.banner3_url}
            onChange={(e) => {
              this.processBannerUrl(e, 3);
            }}
          />
          <label htmlFor="url3" className="btn btn-success">
            PODAJ ADRES URL POWIĄZANY Z BANEREM
          </label>
          {this.state.banner3_value !== "" ? (
            <div className="fade-info bg-light">
              Zostanie Załadowana grafika:{" "}
              <div className="text-success">{this.state.banner3_value}</div>
            </div>
          ) : (
            ""
          )}
          {this.state.banner3_up ? (
            <img
              className="banner p-0"
              src={this.state.banner3_value}
              alt="banner 3"
            />
          ) : (
            ""
          )}
        </div>
        <input
          type="submit"
          id="submit-b"
          className="d-none"
          onClick={(e) => this.saveBanners()}
        />
        <label htmlFor="submit-b" className="m-4 btn btn-success">
          Zapisz konfiguracje banerów
        </label>
      </div>
    );
  }

  render() {
    if (this.state.token === "") {
      return <AdminPanel />;
    }
    return (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.3 }}
      >
        <AdminNavigator />
        {this.showBanersConfig()}
        <Footer />
      </motion.div>
    );
  }
}

export default BannersSection;
